import React, { useState } from "react";
import Index from "../../../pages/admin/Index";
import PagesIndex from "../../../pages/admin/PagesIndex";
import "./Login.css";
import "./Login.responsive.css";
import { AdminLogged } from "../../../../redux/admin/Action";
import { UgoButtonLoader } from "../../../../component/common/UgoLoader";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(false);
  const submitForm = (values ) => {
    try {
      dispatch(AdminLogged(values, navigate, setLoading));
    } catch (error) {}
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

 
  
  return (
    <div>
      <Index.Box>
        <Index.Box className="">
          <Index.Box className="set-login">
            <Index.Box className="leftbox">
              <Index.Box className="ugo-login-set">
                <Index.Box className="logo-set">
                  <img src={PagesIndex.Svg.uplogo} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="ugo-logo-text">
                  <Index.Typography variant="body1" component="p" className="">
                    UGO OUT
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="ugo-logo-inner">
                  <Index.Typography variant="body1" component="p" className="">
                    Your Key To Free Rides
                    <br /> & Offers.
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <PagesIndex.Formik
              validationSchema={PagesIndex.adminLoginSchema}
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={submitForm}

            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
              }) => (
                <form          onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}>
                  <Index.Box className="rightbox">
                    <Index.Box className="res-set-login">
                      <Index.Box className="main-box">
                        <Index.Box>
                          <Index.Box className="box-text">
                            <Index.Typography
                              variant="body1"
                              component="p"
                              className=""
                            >
                              Admin Login
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="input-design-div admin-design-div input-design-ugo">
                            <Index.Stack
                              component="form"
                              spacing={2}
                              noValidate
                              autoComplete="off"
                            >
                              <Index.Box className="main-email-set">
                                <Index.TextField
                                  hiddenLabel
                                  name="email"
                                  onBlur={handleBlur}
                                  type="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  id="filled-hidden-label-normal"
                                  placeholder="Email*"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                />

                                {errors.email && touched.email ? (
                                  <Index.Typography className="error-msg">
                                    {errors.email}
                                  </Index.Typography>
                                ) : null}
                                <Index.Box>
                                  <Index.Box className="email-set">
                                    <img
                                      src={PagesIndex.Svg.email}
                                      alt="logo"
                                      className=""
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="main-email-set">
                                <Index.FilledInput
                                  className="set-password-box input-placeholder"
                                  id="filled-adornment-password"
                                  placeholder="Password*"
                                  name="password"
                                  onBlur={handleBlur}
                                  value={values.password}
                                  variant="filled"
                                  onChange={handleChange}
                                  type={showPassword ? "text" : "password"}
                                 
                                  endAdornment={
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  }
                                />
                                {errors.password && touched.password ? (
                                  <Index.Typography className="error-msg">
                                    {errors.password}
                                  </Index.Typography>
                                ) : null}
                                <Index.Box>
                                  <Index.Box className="email-set">
                                    <img
                                      src={PagesIndex.Svg.lock}
                                      alt="logo"
                                      className=""
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Stack>
                          </Index.Box>
                          <Index.Box className="common-btn login-btn login-btn-main">
                            <Index.Button
                              variant="contained"
                              type="submit"
                              disabled={loading}
                              style={{ color: "white" }}
                              startIcon={loading ? <PagesIndex.UgoButtonLoader /> : null}
                            >
                              Login
                            </Index.Button>
                          </Index.Box>
                          <Index.Box className=" ugo-login-text forgot"></Index.Box>
                          <Index.Box className="box-login-text forgot">
                            <PagesIndex.Link to="/forgotPassword">
                              <Index.Typography
                                variant="body1"
                                component="p"
                                className=""
                              >
                                Forgot password?
                              </Index.Typography>
                            </PagesIndex.Link>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Login;
